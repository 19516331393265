<template>
    <div class="page-wrapper">
        <span v-html="content.contenu"></span>
    </div>
</template>

<script>
// @ is an alias to /src
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'Infolettre',

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    initInViewAnimations()
                }
            },
            immediate: true,
        },
    },

    data() {
        return {}
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content']),
    },
}
</script>

<style lang="scss" scoped></style>
